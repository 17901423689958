import React, { useEffect, useState } from 'react';
import Accordion from "./Accordion.jsx"
import { Skeleton } from "@mui/material"
import { PaperCustom } from './style.jsx';
import api from '../../api/api.js';

const CalculadoraFormats = () => {
    const [expanded, setExpanded] = useState(false);
    const [formats, setFormats] = useState([]);

    useEffect(() => {
        var getAllFormats = "/Formats/formats";

        api.get(getAllFormats).then((response) => {
            return response.data;
        }).then((data) => {
            setFormats(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const handleChange =
        (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };

    useEffect(() => {

    }, [])

    return (
        <>
            {formats.length > 0 ?
                <PaperCustom
                    elevation={0}
                    variant="outlined"
                >
                    {formats && formats.map((format, i) => {
                        return <Accordion key={i} id={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} format={format} />
                    })} </PaperCustom>
                :
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={window.innerWidth < 500 ? '100%' : '40%'}
                    height={window.innerWidth < 500 ? '50vh' : '90%'}
                />
            }
        </>
    );
}

export default CalculadoraFormats;