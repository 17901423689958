import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import BudgetHeader from "../../components/BudgetHeader/BudgetHeader";
import { Link, useNavigate, useParams } from "react-router-dom"
import BudgetBody from "../../components/BudgetBody/BudgetBody";
import api from "../../api/api";
import { checkToken } from "../../utils/methods";
import { Skeleton } from "@mui/material";

const BudgetPage = () => {
    const [budget, setBudget] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        checkToken();
        var getBudgetURL = '/Job/GetJobWithBudgetStatus'
        var formData = { jobId: id }

        api.post(getBudgetURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setBudget(data)
        }).catch((error) => {
            if (error.response.data === "Não foram encontrados jobs referentes a empresa.") {
                navigate('/budgets')
            }
            console.log(error)
        })
    }, [])

    return (<>
        {budget ?
            <Container>
                <PageHeader>
                    <Link to={"/budgets"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>
                </PageHeader>
                <BudgetHeader budget={budget} />
                <BudgetBody budget={budget} />
            </Container>
            :
            <Container>
                <PageHeader>
                    <Link to={"/budgets"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>
                </PageHeader>
                <Skeleton variant="rounded" width={"100%"} height={"10%"} />
                <Skeleton variant="rounded" width={"100%"} height={"15%"} />
            </Container>
        }
    </>
    )
}

export default BudgetPage;