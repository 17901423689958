import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, PasswordPaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"
import PasswordStrenghChecker from "../PasswordStrenghChecker/PasswordStrenghChecker";
import api from "../../api/api"
import Backdrop from "../Backdrop/Backdrop"
import { checkPasswordRequirements } from "../../utils/methods"

const LoginForm = () => {
    const [loading, setLoading] = useState(false);
    const [strength, setStrength] = useState({});
    const [authForm, setAuthForm] = useState({
        password: '',
        confirm_password: '',
    });

    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }

    const getParamsFromURL = (url) => {
        const queryString = url.split('?')[1];

        if (!queryString) return {};

        const pairs = queryString.split('&');

        return {
            token: pairs[0].replace('token=', '') || null,
            id: pairs[1].replace('id=', '') || null,
        };
    }

    const { token, id } = getParamsFromURL(window.location.search);



    const handleAuthentication = (e) => {
        e.preventDefault()
        var logPasswordErrors = checkPasswordRequirements(strength);

        if (logPasswordErrors.length > 0) {
            window.alert(`A senha deve conter pelo menos:\n${logPasswordErrors.toString().replaceAll(",", ", ")}.`
            );
        }

        if (logPasswordErrors.length === 0) {
            if (authForm.confirm_password === authForm.password && authForm.password !== '' && authForm.confirm_password !== '') {
                var createPasswordURL = '/Authentication/RessetPassword'
                var formData = {
                    ressetToken: token,
                    email: id,
                    password: authForm.password
                }

                setLoading(true);
                api.post(createPasswordURL, formData)
                    .then((response) => {
                        return response.data
                    })
                    .then((data) => {
                        setLoading(false)
                        window.localStorage.setItem('jwt__token', data);
                        window.location.href = '/';
                    })
                    .catch((e) => {
                        console.error(e)
                    })
            } else {
                return false
            }
        }
    }

    return (
        <PasswordPaper elevation={2} >
            <p className="headline-small">Conclua seu cadastro</p>
            <AuthForm onSubmit={handleAuthentication}>
                <TextFieldComp
                    name={"password"}
                    label={"Crie uma senha"}
                    type={"password"}
                    value={authForm.password}
                    onChange={handleCredentials}
                    fullWidth={true}
                    placeholder="Digite sua senha"
                    helperText={authForm.password > 0 && authForm.password < 8 && "Senha muito curta"}
                    required
                />

                <PasswordStrenghChecker
                    strength={strength}
                    setStrength={setStrength}
                    password={authForm.password}
                />

                <TextFieldComp
                    error={authForm.confirm_password !== authForm.password}
                    name={"confirm_password"}
                    label={"Confirme sua senha"}
                    value={authForm.email}
                    onChange={handleCredentials}
                    type={"password"}
                    fullWidth={true}
                    placeholder="Digite a senha criada"
                    helperText={authForm.confirm_password !== authForm.password && "Senha acima não corresponde á senha criada."}
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>
            {loading &&
                <Backdrop open={loading} />}
        </PasswordPaper>
    )
}

export default LoginForm;