import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, ProfilePaper } from "./style"

const ProfileForm = ({ userData }) => {

    return (
        <ProfilePaper elevation={2} >
            <p className="headline-small">Meu Perfil</p>
            <AuthForm>
                <TextFieldComp
                    name={"email"}
                    label={"E-mail"}
                    value={userData?.email}
                    onChange={() => { }}
                    type={"text"}
                    fullWidth={true}
                    placeholder="user@gmail.com"
                    readOnly />

                <p className="label-medium">
                    Em breve: Redefinição de Senha
                </p>
            </AuthForm>
        </ProfilePaper>
    )
}

export default ProfileForm;