import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import BudgetsTable from "../../components/BudgetsTable/BudgetsTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import api from "../../api/api";
import { checkToken } from "../../utils/methods";
import { CircularProgress } from "@mui/material";


const BudgetsListPage = () => {
    const [budgets, setBudgets] = useState([]);
    const [filteredBudgets, setFilteredBudgets] = useState([...budgets]);
    const company = JSON.parse(window.localStorage.getItem('user__obj'))?.company;
    const [requestStatus, setRequestStatus] = useState("INITIAL")

    useEffect(() => {
        if (company === null) {
            window.location.href = "/"
        }
        checkToken();
        var allBudgetsURL = '/Job/GetJobsWithBudgetStatusByCompany'
        var formData = { companyId: company }

        api.post(allBudgetsURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                setBudgets(data);
                setRequestStatus("LOADED")
                window.localStorage.setItem('companyName', data[0].companyName)
            }).catch((error) => {
                if (error.status === 404) {
                    setRequestStatus("NOT FOUND")
                    console.log(error)
                } else {
                    window.location.href = "/"
                }
            })
    }, [])

    useEffect(() => {
        setFilteredBudgets([...budgets])
    }, [budgets])

    const filterBudgetList = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredList = budgets.filter((item) =>
            item.jobDescription.toLowerCase().includes(searchTerm))

        if (e.target.value === '' || e.target.value === undefined) {
            setFilteredBudgets(budgets);
        } else {
            setFilteredBudgets(filteredList)
        }
    }

    return (
        <Container>
            <PageHeader>
                <p className="headline-small"><u>Meus Budgets</u></p>
                <SearchBar onChange={filterBudgetList} placeholder={"Pesquise um nº..."} />
            </PageHeader>

            {requestStatus === "LOADED" &&
                <BudgetsTable budgets={budgets} filteredBudgets={filteredBudgets} setFilteredBudgets={setFilteredBudgets} />}
            {requestStatus === "NOT FOUND" && <p className="title-medium">Ah, ainda não existem budgets.</p>}
            {requestStatus === "INITIAL" && <div className="loading-container">
                <CircularProgress color="inherit" />
                <p className="title-medium">Carregando...</p>
            </div>}
        </Container>
    )
}

export default BudgetsListPage;