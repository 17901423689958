import React, { useState, useEffect } from 'react';
import { BudgetContainer, Details, HeaderSection } from './style'
import QuotationSummary from '../QuotationSummary/QuotationSummary'
import BudgetTerms from '../BudgetTerms/BudgetTerms'
import { VerticalAlignBottom } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CircularProgress, Backdrop } from '@mui/material';
import { downloadPDFVersion, getBudgetVersions } from "../../api/budgetApi"
import { downloadPDFFromRequest } from "../../utils/methods"
import { Skeleton } from "@mui/material";

const BudgetBody = ({ budget }) => {
    const [version, setVersion] = useState(null)
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleGetVersions = async () => {
        var formData = { baseBudgetId: budget.baseBudgetId }
        var message = await getBudgetVersions(formData)
        if (message) {
            message.forEach((item) => {
                if (item.isActive === true) {
                    setVersion(item)
                }
            })
        }
    }

    const handleDownloadPDFVersion = async () => {
        setOpenBackdrop(true);
        var formData = {
            jobId: budget.jobId,
            budgetId: version.budgetVersionId
        }

        var filename = `${budget.companyName.replace(" ", "")}-orcamento-V${version.version}.pdf`
        var message = await downloadPDFVersion(formData)
        if (message) {
            downloadPDFFromRequest(message, filename)
            setOpenBackdrop(false);
        }
    }

    useEffect(() => {
        if (budget) {
            handleGetVersions();
        }
    }, [budget])

    return (
        <>{version ?
            <BudgetContainer variant='outlined'>
                <HeaderSection>
                    <div className='title-container'>
                        <p className='title-small'>Versão {version.version}{"   "}|{"   "}{version.createdAt}</p>
                    </div>
                    <Link className='title-small' onClick={handleDownloadPDFVersion}>
                        Baixar carta
                        <VerticalAlignBottom fontSize='small' />
                    </Link>
                </HeaderSection>

                <Details>
                    <QuotationSummary summary={version.budgetResume} due={version.due} />
                    <BudgetTerms terms={version.terms} />
                </Details>


                {openBackdrop &&
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={openBackdrop}
                        onClick={() => { setOpenBackdrop(false) }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>}
            </BudgetContainer> :
            <Skeleton variant="rounded" width={"100%"} height={"100%"} />
        }
        </>
    );
};

export default BudgetBody;
