import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "../ApproveBudgetModal/style"
import api from "../../api/api";
import AssetUpload from "../../components/AssetUpload/AssetUpload";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

const UploadModal = ({ open, handleClose, job, selectedFiles, setSelectedFiles }) => {
    const [progress, setProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('INIT');

    const handleSendFiles = () => {
        var submitAsset = '/BlobStorage/submit-asset/'

        var formData = new FormData();
        formData.append('CompanyName', job.companyName)
        formData.append('JobId', job.id)
        formData.append('File', selectedFiles[0])

        api.post(submitAsset, formData, {
            onUploadProgress: (event) => {
                setUploadStatus('LOADING');
                let progress = Math.round(
                    (event.loaded * 100) / event.total
                );
                setProgress(progress);
            },
        }).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Upload de arquivo realizado com sucesso!") {
                changeJobStatusToReview();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const changeJobStatusToReview = () => {
        var allCompaniesURL = `/Job/${job.id}/UpdateJobStatus/`
        var formData = {
            "statusId": 3
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Atualização feita com sucesso!') {
                    setUploadStatus('DONE');
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                minWidth: '50%'
            }}
        >
            <ModalTitle className="title-large">
                Upload de Assets
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {uploadStatus === "LOADING" || uploadStatus === "DONE" &&
                    <>
                        <p className="headline-small">{progress < 100 ? 'Enviando seus materiais...' : 'Feito!'}</p>
                        <ProgressBar progress={progress} />
                    </>
                }
                <AssetUpload
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setProgress={setProgress}
                    setUploadStatus={setUploadStatus}
                    uploadStatus={uploadStatus}
                    job={job}
                    progress={progress}
                    modal={true}
                />
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleSendFiles} variant={"contained"}>
                    <span className="title-small">
                        Enviar para análise
                    </span>
                </CustomButton>
            </ModalActions>

        </DialogModal>
    )
}

export default UploadModal;