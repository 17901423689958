import React from "react";
import Footer from "../../components/Footer/Footer";
import RegisterForm from "../../components/RegisterForm/RegisterForm";
import { UserPageContainer } from './style'

const LoginPage = () => {
   
    return (
        <UserPageContainer>
            <RegisterForm />
            <Footer />
        </UserPageContainer>
    )
}

export default LoginPage; 