import React, { useContext, useEffect, useState } from "react";
import { BudgetSection, FormatsContainer } from './style.jsx'
import CalculadoraInstructions from '../../components/CalculadoraInstructions/CalculadoraInstructions.jsx'
import PriceResult from "../../components/PriceResult/PriceResult.jsx"
import CalculadoraFormats from "../../components/CalculadoraFormats/CalculadoraFormats.jsx"
import BudgetSavedModal from "../../modals/BudgetSavedModal/BudgetSavedModal.jsx"
import api from "../../api/api.js";
import { BudgetContext } from "../../context/BudgetContext.jsx";
import { checkToken } from "../../utils/methods.js";

const CalculadoraPage = () => {
    const { budgetPayload } = useContext(BudgetContext)
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [loadingBudget, setLoadingBudget] = useState(false);

    useEffect(() => {
        checkToken();
    }, [])

    const submitBudget = () => {
        setLoadingBudget(true);
        var submitBudgetURL = "/Budget/SubmitBudgetLogged";

        api.post(submitBudgetURL, budgetPayload).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Budget criado com sucesso!") {
                setLoadingBudget(false);
                setOpenSaveModal(true);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <BudgetSection>
            <CalculadoraInstructions />
            <FormatsContainer>
                <CalculadoraFormats />
                <PriceResult loadingBudget={loadingBudget} submitBudget={submitBudget} />
            </FormatsContainer>
            <BudgetSavedModal open={openSaveModal} />
        </BudgetSection>
    )
}

export default CalculadoraPage;