import React, { useState, useEffect } from "react";
import { AssetRow } from "../JobBody/style";
import { IconButton } from '@mui/material';
import { VerticalAlignBottom, DeleteOutlined } from '@mui/icons-material';
import DeleteAssetModal from '../../modals/DeleteAssetModal/DeleteAssetModal';
import api from "../../api/api";

const JobAssetRow = ({ job, blob }) => {
    const [openDeleteAsset, setOpenDeleteAsset] = useState(false);

    const handleDeleteAsset = () => {
        setOpenDeleteAsset(true);
    }

    const handleDownloadBlob = async () => {
        var downloadBlobFile = '/BlobStorage/download-asset/'
        var formData = {
            "blobId": blob.id,
            "jobId": job.id,
            "companyName": job.companyName
        }
        try {
            const response = await api.post(downloadBlobFile, formData, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', blob.name);
            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed', error);
        }
    }

    return (
        <AssetRow>
            <span className='label-large'>
                {blob.name}
            </span>
            <div className='action-row'>
                <IconButton onClick={handleDownloadBlob}>
                    <VerticalAlignBottom fontSize='small' />
                </IconButton>
                {/* <IconButton onClick={handleDeleteAsset}>
                    <DeleteOutlined fontSize='small' />
                </IconButton> */}
            </div>
            <DeleteAssetModal open={openDeleteAsset} handleClose={() => { setOpenDeleteAsset(false) }} />
        </AssetRow>
    )
}
export default JobAssetRow;