import api from "./api";

export const getBudgetVersions = async (payload) => {
    try {
        const response = await api.post('/Budget/GetBudgetVersionsByBudget', payload);
        return response.data;
    } catch (error) {
        console.error('Error getting budget versions:', error.response?.data.description);
        // throw error;
    }
};

export const downloadPDFVersion = async (payload) => {
    try {
        const response = await api.post('/Budget/DownloadBudgetVersionPdf', payload, { responseType: 'blob' })
        return response.data;
    } catch (error) {
        console.error('Error activating budget:', error.response?.data.description);
        // throw error;
    }
};


