import React, { useEffect } from 'react'
import styled from 'styled-components';
import colors from "../../utils/colors.json"

const StrengthIndicator = styled.div`
    text-align: left;
    align-self: flex-start;
    margin-top: -10px;
    .label{
        font-weight: 500 !important;
        color: ${colors.validating};
        
    }
    .accepted{
        color: ${colors.success};
    }
`;

const PasswordStrenghChecker = ({ strength, setStrength, password }) => {

    const evaluateStrength = (pass) => {
        if (!pass) {
            setStrength('');
            return;
        }

        const hasUpperCase = /[A-Z]/.test(pass);
        const hasLowerCase = /[a-z]/.test(pass);
        const hasNumbers = /\d/.test(pass);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
        const isLongEnough = pass.length >= 8;

        setStrength(strength => ({
            hasUpperCase: hasUpperCase,
            hasLowerCase: hasLowerCase,
            hasNumbers: hasNumbers,
            isLongEnough: isLongEnough,
            hasSpecialChars: hasSpecialChars
        }))
    };

    useEffect(() => {
        evaluateStrength(password)
    }, [password])

    return (
        <StrengthIndicator strength={strength} className='title-small'>
            Sua senha deve ter:
            <span className={`label ${strength.isLongEnough && 'accepted'}`}> <li> No mínimo 8 caracteres.</li></span>
            <span className={`label ${strength.hasUpperCase && 'accepted'}`}> <li> Pelo menos uma letra maiúscula.</li></span>
            <span className={`label ${strength.hasNumbers && 'accepted'}`}> <li> Pelo menos um número.{`(e.g., (0-9)`} </li></span>
            <span className={`label ${strength.hasLowerCase && 'accepted'}`}> <li> Pelo menos uma letra minúscula.</li></span>
            <span className={`label ${strength.hasSpecialChars && 'accepted'}`}> <li> Pelo menos um caractere especial: {`(e.g., !@#$%^&*())`}</li></span>
        </StrengthIndicator>
    )
}
export default PasswordStrenghChecker